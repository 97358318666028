import React from 'react'
import Menu from '../components/menu'
import Footer from '../components/footer'

export default ({ children }) => (
  <div className='site'>
    <Menu />
    <div className='contentArea'>{children}</div>
    <Footer />
  </div>
)
