import React from "react"
import Squeeze from "../layouts/squeeze-landing"
import Hero from "../components/hero"
import Base from "../layouts/base"
import SEO from "../components/SEO"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import WhereToBuy from "../components/whereToBuy"
import TextLeftImgRight from "../components/textLeftImgRight"
import ImgLeftTextRight from "../components/imgLeftTextRight"

export default () => (
  <div>
    <SEO
      title="Sports CeramicSteel Whiteboards | Magnetic Dry Erase Boards"
      description="This is the description"
    />
    <Base>
      <Hero
        name="Sports"
        headline="Lead Your Team to Victory"
        text="Customize your whiteboard with a high quality field diagram so you can strategize and win the day for your team."
        image="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/football_with_football_text_-_cropped.png"
        color="rgb(255, 130, 90)"
      />
      <Squeeze>
        <TextLeftImgRight
          heading="Lasting Impression"
          p1="Panls dry erase boards are customizable with a sports field diagram of your choice.
          We use state-of-the-art screen and digital printing
          technologies to place your field diagram on the surface of your whiteboard. The whiteboard is
          then fired at 700–900&deg;C (1292–1652&deg;F) to fuse the diagram with the whiteboard's the top coating.
          Thanks to this process, your board retains the same writing properties it was created with
          and your field diagram will never fade."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/sports-soccer.jpg"
          buybtn="d-none"
        />
        <div className="row mb-4 d-flex text-center">
          <div className="six columns py-2 px-2 border">
            <h2 className="mb-1">Basketball</h2>
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/w_auto,e_trim,c_fill,g_auto/assets/1/26/Panls-Basketball4x6.jpg"
              className="mb-1 img-fluid"
            />
          </div>
          <div className="six columns py-2 px-2 border">
            <h2 className="mb-1">Football</h2>
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/w_auto,e_trim,c_fill,g_auto/assets/1/26/Panls-Football4x6.jpg"
              className="mb-1 img-fluid"
            />
          </div>
        </div>
        <div className="row mb-4 d-flex text-center">
          <div className="six columns py-2 px-2 border">
            <h2 className="mb-1">Lacrosse</h2>
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/w_auto,e_trim,c_fill,g_auto/assets/1/26/Panls-Lacrosse4x6.jpg"
              className="mb-1 img-fluid"
            />
          </div>
          <div className="six columns py-2 px-2 border">
            <h2 className="mb-1">Soccer</h2>
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/w_auto,e_trim,c_fill,g_auto/assets/1/26/Panls-Soccer4x6.jpg"
              className="mb-1 img-fluid"
            />
          </div>
        </div>
        <div className="row mb-4 text-center">
          <h2 className="mb-1">Find the Perfect Fit for Your Space</h2>
          <p>The Panls Sports line of boards is currently available in two sizes 4'x4' and 4'x6'. </p>
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/panls-sports-size-diagram.png"
            className="img-fluid"
          />
        </div>
        <div class="row mb-4 py-2 px-2 text-center bg-light-grey border">
          <h2 className="mb-1">See it in action!</h2>
          <div className="embed-responsive embed-responsive-16by9">
              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/oORd0DrOOdc" allowfullscreen></iframe>
          </div>
        </div>
        <div className="row mb-4 d-flex text-center">
          <div className="six columns py-2 px-2 bg-light-grey border">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/configure-icon.png"
              className="mb-1"
            />
            <h2 className="mb-1">Customized for You</h2>
            <p>
              We offer high quality sports field overlays for football,
              basketball, soccer and lacrosse. Our overlays can be applied to
              any of Panls CeramicSteel whiteboards. Need a football field
              diagram on a Dash portable whiteboard for your locker room? No
              problem. Please note that additional lead times will apply to
              custom orders.
            </p>
          </div>
          <div className="six columns py-2 px-2 bg-light-grey border">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/check-mark-icon.png"
              className="mb-1"
            />
            <h2 className="mb-1">Fast, Easy Cleanup</h2>
            <p>
              Our custom whiteboards are resistant to virtually all permanent
              marking materials and the cleaners needed to remove them. That
              means you are safe to use nylon scouring pads, steel wool and
              solvent-based cleaners like acetone, alcohol, or mineral spirits
              on our whiteboards even with custom sports diagrams applied.
            </p>
          </div>
        </div>
        <div className="row mt-3 mb-4 px-1 py-2 d-flex-center bg-light-grey border">
          <h3 className="mb-1 mt-2 div-heading mx-1">More than sports.</h3>
          <p className="mb-1 px-1">
            With Panls, customization options are available for more than just
            sports diagrams. We also offer custom image or pattern overlays of
            your choosing, whether it be a company logo or pattern to match your
            room design. All designs are fused directly to your Panls whiteboard
            using the same state-of-the-art process we use for our sports field
            diagrams. Please note that there will be an extended lead time for
            all customizations.
          </p>
          <br></br>
        </div>
        <div className="row mb-4 d-flex text-center">
          <div className="six columns py-2 px-2 bg-light-grey border">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/diamond-icon.png"
              className="mb-1"
            />
            <h2 className="mb-1">Outstanding Durability</h2>
            <p>
              Panls customized CeramicSteel whiteboards retain the same
              durability as our standard products. Our field diagrams are fused
              directly into the top layer of the dry erase surface, meaning they
              will never fade. Custom diagrams will also resist damage from
              fire, scratches, chemicals, impacts, and bacteria.
            </p>
          </div>
          <div className="six columns py-2 px-2 bg-light-grey border">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/ribbon-icon.png"
              className="mb-1"
            />
            <h2 className="mb-1">Lifetime Warranty</h2>
            <p>
              Our custom whiteboards are resistant to virtually all permanent
              marking materials and the cleaners needed to remove them. That
              means you are safe to use nylon scouring pads, steel wool and
              solvent-based cleaners like acetone, alcohol, or mineral spirits
              on our whiteboards even with custom sports diagrams applied.
            </p>
          </div>
        </div>
        <WhereToBuy />
      </Squeeze>
    </Base>
  </div>
)
