import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default props => (
  <div className="row mt-3 mb-4 px-1 column-flip d-flex-center">
    <div className="six columns">
      <h2 className="mb-1 mt-2 div-heading">{props.heading}</h2>
      <p className="mb-1">{props.p1}</p>
      <p className="mb-1">{props.p2}</p>
      <p className={`${props.buybtn} section-link mb-2`}>
        <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/buy/">
          How to Buy →
        </AniLink>
      </p>
    </div>
    <div className="six columns">
      <img src={props.img} className="img-fluid" />
    </div>
  </div>
)
