import React from "react"
import styles from "./hero.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default props => (
  <div className={`${styles.heroRow} row bg-light-grey`}>
    <div className="six columns px-2 py-2">
      <h1 className={styles.heroName} style={{ backgroundColor: props.color }}>
        {props.name}
      </h1>
      <h2 className="mb-2">{props.headline}</h2>
      <p className="">{props.text}</p>
      <AniLink
        paintDrip
        hex="#ea4c12"
        duration={0.75}
        to="/buy/"
        className={`${styles.heroBtn} btn-standard mt-3`}
        style={{ borderColor: props.color }}
      >
        How to Buy →
      </AniLink>
    </div>
    <div
      className={`${styles.heroImage} six columns`}
      style={{ backgroundImage: `url(${props.image})` }}
    >
      <img
        src={props.image}
        className={`${styles.heroMobileImage} img-fluid`}
      />
    </div>
  </div>
)
